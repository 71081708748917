<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(defineProps<{
  id: string,
  label: string,
  type?: string,
  name?: string,
  placeholder?: string,
  modelValue: string | number,
  error?: string,
  disabled?: boolean,
  required?: boolean,
  hint?: string
}>(), {
  type: 'text',
  placeholder: ''
})

const emit = defineEmits(['update:modelValue'])

const internalValue = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

</script>
<template>
  <div class="grid gap-2">
    <Label :for="id">{{ label }}</Label>
    <Input :id="id" :name="name" :type="type" :placeholder="placeholder" v-model="internalValue" :required="required"
      :disabled="disabled" />
    <Error v-if="error">{{ error }}</Error>
    <p v-else-if="hint" class="ml-1" :id="id + '-help-text'">
      {{ hint }}
    </p>
  </div>
</template>
